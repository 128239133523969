/***************************************************************************************************
 * BROWSER POLYFILLS
 */

import 'core-js/es/symbol';
import 'core-js/es/object';
import 'core-js/es/function';
import 'core-js/es/parse-int';
import 'core-js/es/parse-float';
import 'core-js/es/number';
import 'core-js/es/math';
import 'core-js/es/string';
import 'core-js/es/date';
import 'core-js/es/array';
import 'core-js/es/regexp';
import 'core-js/es/map';
import 'core-js/es/weak-map';
import 'core-js/es/set';
import 'core-js/es/reflect';
import 'classlist.js';

/**
 * old for core-js 2
 * import 'core-js/es6/symbol';
 * import 'core-js/es6/object';
 * import 'core-js/es6/function';
 * import 'core-js/es6/parse-int';
 * import 'core-js/es6/parse-float';
 * import 'core-js/es6/number';
 * import 'core-js/es6/math';
 * import 'core-js/es6/string';
 * import 'core-js/es6/date';
 * import 'core-js/es6/array';
 * import 'core-js/es6/regexp';
 * import 'core-js/es6/map';
 * import 'core-js/es6/weak-map';
 * import 'core-js/es6/set';
 * import 'core-js/es6/reflect';
 */

/**
 * Web Animations `@angular/platform-browser/animations`
 * Only required if AnimationBuilder is used within the application and using IE/Edge or Safari.
 * Standard animation support in Angular DOES NOT require any polyfills (as of Angular 6.0).
 */
// import 'web-animations-js';  // Run `npm install --save web-animations-js`.

/**
 * By default, zone.js will patch all possible macroTask and DomEvents
 * user can disable parts of macroTask/DomEvents patch by setting following flags
 * because those flags need to be set before `zone.js` being loaded, and webpack
 * will put import in the top of bundle, so user need to create a separate file
 * in this directory (for example: zone-flags.ts), and put the following flags
 * into that file, and then add the following code before importing zone.js.
 * import './zone-flags.ts';
 *
 * The flags allowed in zone-flags.ts are listed here.
 *
 * The following flags will work for all browsers.
 *
 * (window as any).__Zone_disable_requestAnimationFrame = true; // disable patch requestAnimationFrame
 * (window as any).__Zone_disable_on_property = true; // disable patch onProperty such as onclick
 * (window as any).__zone_symbol__UNPATCHED_EVENTS = ['scroll', 'mousemove']; // disable patch specified eventNames
 *
 *  in IE/Edge developer tools, the addEventListener will also be wrapped by zone.js
 *  with the following flag, it will bypass `zone.js` patch for IE/Edge
 *
 *  (window as any).__Zone_enable_cross_context_check = true;
 *
 */

(window as any).__Zone_enable_cross_context_check = true;

/***************************************************************************************************
 * Zone JS is required by default for Angular itself.
 */
import 'zone.js';  // Included with Angular CLI.




/***************************************************************************************************
 * APPLICATION IMPORTS
 */
 (window as any).global = window;
 (window as any).process = {
    env: { DEBUG: undefined },
};
import { Buffer } from 'buffer';

(window as any).Buffer = Buffer;
